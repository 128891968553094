.main-part{
    position: relative;
    padding: 150px 0 40px 0;

    &::before {
        content: ' ';
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.07;
        background-image: url('../../../public/images/HomePage/patern.png');
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
    }
}