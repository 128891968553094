.home {
    position: relative;

    &__content {
        position: relative;
        z-index: 1;
        text-align: center;
    }

    &__logo {
        margin: 0 auto 50px auto;
        // margin-bottom: 50px;
        max-width: 1000px;
    }

    &__text {
        margin-bottom: 52px;
    }

    &__button {
        display: inline-block;
        margin-bottom: 40px;
        font-size: 24px;
        padding: 20px 30px;
        background: #ffffff;
        @media (max-width: 700px) {
            font-size: 18px;
            
        }
    }

    &__social {
    }
}

.text-home {
    font-size: 27px;
    font-weight: 300;
    @media (max-width: 700px) {
        font-size: 18px;
        padding: 0 15px;
    }

    &__title {
        margin-bottom: 15px;
    }

    &__subtitle {
        color: #ed696b;
    }
}

.social-home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 700px) {
        justify-content: space-around;
    }

    &__column {
        display: flex;
        align-items: center;
        // background: #000;
        & a {
            cursor: pointer;
            margin-right: 10px;

            &:last-child {
                margin: 0;
            }
        }
        &:first-child{
            // background: #000;
            a:first-child > img{
                // display: block;
                width: 81%;
            }
        }
    }
}

.marquee-first {
    color: #ffffff;
    background: #ed696b;
}

.marquee-second {
    color: #ffffff;
    background: #5e6c87;
}
.marquee-third {
    color: #ffffff;
    background: #a6d0dd;
}

//<>=======================================================================================================
