.where {
    padding: 50px 0 100px 0;
    position: relative;

    &::before {
        content: " ";
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.07;
        background-image: url("../../../public/images/HomePage/patern.png");
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
    }

    &__title {
        position: relative;
        z-index: 2;
        color: #5e6c87;
        font-size: 36px;
        font-weight: 800;
        margin-bottom: 45px;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 80px;
        @media (max-width: 840px) {
            flex-direction: column;
            margin-bottom: 35px;
        }
    }
    &__adresses {
        margin-right: 20px;
    }
    &__map {

    }

    &__btns{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 840px) {
            flex-direction: column;
            justify-content: center;
        }
    }

    &__btn{
        position: relative;
        z-index: 2;
        width: 100%;
        margin-right: 40px;
        background: #fff;
        padding: 32px 30px;
        font-size: 24px;
        &:last-child{
            margin: 0;
        }
        @media (max-width: 1110px) {
            font-size: 18px;
            padding: 20px 20px;
        }
        @media (max-width: 840px) {
            margin: 0 0 20px 0;
        }
        @media (max-width: 420px) {
            font-size: 16px;
            padding: 15px 10px;
        }
    }
    &__container{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.where-page{
    padding-top: 110px;
}

.adress {
    position: relative;
    z-index: 2;

    &__container {
        cursor: pointer;
        font-size: 20px;
        margin-bottom: 25px;
        padding-left: 40px;
        background: url("../../../public/images/HomePage/WhereWe/adress.svg") -10px -7px
            no-repeat;

        &.active {
            background: url("../../../public/images/HomePage/WhereWe/adress-active.svg") -10px -7px
                no-repeat;
            .adress__name {
                color: #ed696b;
            }
        }
    }

    &__name {
        width: 80%;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
        @media (max-width: 1100px) {
            font-size: 14px;
        }
    }

    &__text {
        @media (max-width: 1100px) {
            
            font-size: 14px;
        }
    }

    &__map {
        position: relative;
        z-index: 2;
        min-width: 500px;
        height: 500px;
        overflow: hidden;
        @media (max-width: 1100px) {
            
            min-width: 400px;
            height: 400px;
        }
        @media (max-width: 430px) {
            
            min-width: 100%;
            height: 350px;
        }
    }
}
.modal-first{
    // top: 680px !important;
}

.where-first-train{
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    max-width: 700px;
    padding: 20px;
    // border: 1px solid #000;
    border-radius: 20px;
    background: #ff6969;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.4);
    color: #fff;
    &__row{
        
        text-align: center;
    }
    &__title{
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: 700;
        @media (max-width: 600px) {
            font-size: 20px;
        }
    }

    &__text{
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 10px;
        @media (max-width: 600px) {
            font-size: 16px;
        }
    }
    &__under-text{
        font-size: 14px;
        margin-bottom: 40px;
        font-weight: 200;
        @media (max-width: 600px) {
            font-size: 10px;
        }
    }

    &__form{}
}
