.why-club {
    position: relative;
    padding: 50px 0 100px 0;

    &::before {
        content: " ";
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.07;
        background-image: url("../../../public/images/HomePage/patern.png");
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
    }

    &__title {
        color: #ed696b;
        font-size: 36px;
        font-weight: 800;
        margin-bottom: 45px;
    }

    &__row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 5px;
        @media (max-width: 1050px) {
            grid-template-columns: 1fr;
            grid-row-gap: 5px;
        }
    }

    &__column {
        &:first-child {
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-row-gap: 5px;
        }
        &:nth-child(2){
            .back-card__title{
                @media (max-width: 1200px) {
                    font-size: 18px;
                    margin-bottom: 30px;
                }
                // @media (max-width: 525px) {
                //     font-size: 15px;
                // }
                // @media (max-width: 390px) {
                //     margin-bottom: 10px;
                // }
            }
            .back-card__text{
                @media (max-width: 1200px) {
                    font-size: 16px;
                }
            }
        }
    }

    &__card {
        width: 100%;
        height: 100%;

        position: relative;
        z-index: 2;
        border-radius: 30px;
        border: 1px solid #000;
        background: #fff;
        overflow: hidden;
    }
}