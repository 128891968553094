.modal-forgot {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(217, 217, 217, 0.95);
    z-index: 49;
    padding: 20px;
    &.active {
        display: block;
    }
    &__block {
        padding: 20px;
        max-width: 600px;
        width: 100%;
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #ff6969;
        border-radius: 20px;
        position: relative;
        min-height: 370px;
        overflow: hidden;
    }
    &__container{
        position: absolute;
        right: 20px;
        display: grid;
        width: calc(100% - 40px);
        grid-template-columns: 100% 100% 100% 100%;
        gap: 20px;
        transition: right 0.5s ease;

    }
    &__title {
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 10px;
    }
    &__progress{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
        & span{
            height: 6px;
            width: 100%;
            border: 2px solid #DB5555;
            background: transparent;
            border-radius: 20px;
            &.active{
                background: #DB5555;
            }
        }
        margin-bottom: 20px;
    }
}
.block{
    display: grid;
    grid-template-rows: 20% 1fr 20%;
    align-items: center;
    // position: absolute;
}
.block-email {
    &__text {
        color: #fff;
        font-size: 17px;
        font-weight: 200;
        line-height: normal;
        margin-bottom: 25px;
        @media (max-width: 368px) {
            font-size: 14px;
        }
    }
    &__btn {
        cursor: pointer;
        border-radius: 10px;
        background: #4b4242;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 15px 30px;
        @media (max-width: 830px) {
            font-size: 16px;
        }
    }
}

.block-complited{
    justify-items: center;
    &__img{
        height: 70px;
        width: 70px;
        & img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &__text{
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
    }
}
