.trainingPage {
    padding-top: 150px;
    padding-bottom: 100px;
    position: relative;
    &::before {
        content: " ";
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.07;
        background-image: url("../../../public/images/HomePage/patern.png");
        background-repeat: repeat;
        background-position: 50% 0;
        // background-size: ;
    }
    &__title {
        color: #5b5b5b;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 50px;
    }

    &__tabs {
        @media (max-width: 866px) {
            display: none;
        }
    }
    &__mobile {
        display: none;
        @media (max-width: 866px) {
            display: block;
        }
    }

    &__bg {
        display: none;
        position: fixed;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(217, 217, 217, 0.95);
        z-index: 49;
        padding: 10px;
        &.active {
            display: block;
        }
    }

    &__adress {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
        color: #000;
    }
    &__description {
        margin-bottom: 30px;
    }
    &__btns {
        position: relative;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__more-btn {
        cursor: pointer;
        display: inline-block;
        // background: #ea7777;
        // color: #fff;
        border: 1px solid #000;
        background: #fff;
        box-shadow: 2px 2px 0px 0px #000;

        color: #000;
        text-align: center;
        padding: 10px 15px;
        border-radius: 10px;
        // border: 1px solid #000;
        // box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.76);
    }
}
.modal-more-info {
    color: #fff;
    position: relative;
    z-index: 4;
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
    z-index: 50;
    max-width: 800px;
    width: 100%;
    background: #ea7777;
    padding: 30px 30px;
    border-radius: 20px;
    display: flex;
    // align-items: center;
    flex-direction: column;
}
.tabs-info {
    margin-bottom: 20px;
    position: relative;
    z-index: 4;
    // display: flex;
    // align-items: center;
    &__labels {
        display: flex;
        align-items: center;
        justify-content: space-around;
        list-style: none;
    }
    &__tab {
        // display: block;
        cursor: pointer;
        position: relative;
        font-size: 24px;
        font-weight: 300;
        width: 20%;
        border-radius: 15px 15px 0px 0px;
        background: #5b5b5b;
        color: #fff;
        text-align: center;
        padding: 10px 0;
        transition: all 0.5s ease-in-out;

        &.active {
            width: 30%;
            background: #eaeaea;
            color: #000;
            &::before {
                content: "";
                display: block;
                background: radial-gradient(
                    circle at 100% 0,
                    rgba(0, 0, 0, 0) 16px,
                    #eaeaea 10px
                );

                background-position: bottom left;
                background-size: 50% 50%;
                background-repeat: no-repeat;
                position: absolute;
                bottom: 0;
                right: -33px;
                width: 33px;
                height: 33px;
            }
        }
    }

    &__content {
        background: #eaeaea;
        padding: 3px 35px 3px 3px;
        border-radius: 10px;
        margin-bottom: 20px;
        // &.active{
        //     display: none;
        // }
    }
}

.training-none {
    margin-bottom: 20px;
    background: #5b5b5b;
    color: #eaeaea;
    padding: 10px 10px;
    border-radius: 10px;
    text-align: center;
    // text-transform: uppercase;
}

// .workouts {
//     position: relative;
//     z-index: 4;
//     display: flex;
//     align-items: center;
//     margin-bottom: 3px;
//     ~ &:last-child {
//         margin: 0;
//     }
//     &__date {
//         margin-right: 60px;
//     }

//     &__items {
//         // display: flex;
//         // align-items: center;
//     }

//     &__item {
//     }

//     &__container {
//         display: flex;
//         align-items: center;
//         flex-direction: row;
//     }
// }
.workouts {
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;

    margin-bottom: 3px;
    overflow: hidden;
    @media (max-width: 866px) {
        flex-direction: column;
        // align-items: center;
    }
    ~ &:last-child {
        margin: 0;
    }
    &__date {
        margin-right: 60px;
        @media (max-width: 866px) {
            margin-right: 0;
            margin-bottom: 30px;
        }
    }

    &__items {
        // display: flex;
        // align-items: center;
        // overflow-x: scroll;
        @media (max-width: 866px) {
            // width: 100%;
            // align-items: center;
        }
    }

    &__item {
    }

    &__container {
        display: flex;
        align-items: center;
        flex-direction: row;
        @media (max-width: 866px) {
            flex-direction: column;
        }
    }
}

.date-workouts {
    max-width: 60px;
    // width: 60px;
    flex: 1 0 60px;
    height: 198px;
    background: #ea7777;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    @media (max-width: 866px) {
        max-height: 49px;
        max-width: 100%;
        width: 100%;
        flex: 0 0 50%;
        flex-direction: row;
    }
    &__week {
        padding: 15px 10px 15px 10px;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        border-bottom: 1px solid #eaeaea;
        width: 100%;
        color: #eaeaea;
        @media (max-width: 866px) {
            text-align: center;
            border-right: 1px solid #eaeaea;
            border-bottom: none;
        }
    }

    &__day {
        padding: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transform: rotate(-90deg);
        font-weight: 600;
        font-size: 24px;
        color: #eaeaea;
        @media (max-width: 866px) {
            transform: rotate(0);
            flex: 0 0 50%;
        }
    }
}

.workout {
    min-width: 300px;
    border-radius: 15px;
    background: #5b5b5b;
    // box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.25);
    // overflow: hidden;
    margin-right: 30px;
    @media (max-width: 866px) {
        min-width: none;
        max-width: 100%;
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
        // align-items: center;
    }

    &__row {
    }

    &__column {
    }

    &__info {
    }

    &__time {
    }

    &__trainer {
    }

    &__actions {
    }
}

.workouts-competition {
    padding-top: 10px;
    min-width: 300px;
    border-radius: 15px;
    background: #5b5b5b;
    margin-right: 30px;
    height: 159px;
    @media (max-width: 866px) {
        min-width: none;
        max-width: 100%;
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
        // align-items: center;
    }
    &__info {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }
    &__type {
        color: #fff;
        font-weight: 600;
    }
    &__row {
        // height: 100%;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
    }
    &__btns {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.info-workout {
    position: relative;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: end;
    &__name {
        position: absolute;
        top: 0;
        left: 12px;
        width: 180px;
        // height: 38px;
        border-radius: 0px 0px 15px 15px;
        background: #d9d9d9;
        font-size: 10px;
        font-weight: 700;
        text-align: center;
        // text-transform: uppercase;
        padding: 5px 5px 6px 6px;
    }

    &__btn {
        cursor: pointer;
    }
}

.time-workout {
    position: relative;
    background: #d9d9d9;
    font-size: 10px;
    font-weight: 400;
    padding: 6px;
    margin-bottom: 15px;
    &__label {
        position: absolute;
        top: -40%;
        left: 10px;
        // transform: translateY(50%);
        border-radius: 13px;
        border: 1px solid #d9d9d9;
        background: #5b5b5b;
        padding: 2px 10px;
        color: #fff;
    }

    &__interval {
        text-align: center;
        font-weight: 600;
    }
}

.trainer-workout {
    position: relative;
    background: #d9d9d9;
    font-size: 10px;
    font-weight: 400;
    padding: 6px;
    &__label {
        position: absolute;
        top: -40%;
        left: 10px;
        // transform: translateY(50%);
        border-radius: 13px;
        border: 1px solid #d9d9d9;
        background: #5b5b5b;
        padding: 2px 10px;
        color: #fff;
    }

    &__name {
        text-align: center;
        font-weight: 600;
    }
}

.actions-workout {
    padding: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__people {
    }

    &__btn {
    }
}

.people-actions {
    cursor: pointer;
    position: relative;
    border-radius: 54px;
    background: #d9d9d9;
    padding: 7px 10px 7px 10px;
    max-width: 162px;
    width: 100%;

    // z-index: 2;
    &__circles {
        position: relative;
    }

    &.active {
        // &::before{
        //     content: 'x';
        //     width: 20px;
        //     height: 20px;
        //     background: #d9d9d9;
        //     border-radius: 50%;
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     // text-align: center;
        //     // vertical-align: middle;
        //     font-size: 12px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        // }
    }
    &__exit {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        span {
            position: absolute;
            // top: 50%;
            left: 45%;
            display: block;
            width: 10px;
            height: 1px;
            background: #000;
            &:first-child {
                top: 6px;
                transform: rotate(-45deg) translateX(-50%);
            }
            &:last-child {
                top: 13px;
                transform: rotate(45deg) translateX(-50%);
            }
        }
    }

    &__circle {
        position: absolute;
        top: -2px;
        left: -59px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid #eaeaea;
        background: #000;
        // transform: translateY(-50%);
        &:nth-child(1) {
            left: -58px;
        }
        &:nth-child(2) {
            left: -43px;
        }
        &:nth-child(3) {
            left: -28px;
        }
    }

    &__list {
        display: none;
        max-width: 162px;
        width: 100%;
        position: absolute;
        bottom: -90px;
        left: 0;
        border-radius: 14px;
        padding: 15px 7px 7px 7px;
        list-style: none;
        background: #fff;
        z-index: 10;
        max-height: 200px;
        height: 100%;
        overflow-y: scroll;
    }

    &__item {
    }

    &__count {
    }
}

.item-people {
    display: flex;
    align-items: center;
    height: 30px;
    margin-left: 7px;
    // justify-content: space-between;
    margin-bottom: 7px;
    &:last-child {
        margin-bottom: 0;
    }
    &__img {
        width: 30px;
        height: 30px;
        background: #000;
        border-radius: 50%;
        margin-right: 10px;
    }

    &__name {
        color: #000;
        font-size: 12px;
        font-weight: 300;
    }
}

.count-people {
    &__title {
        font-size: 10px;
        font-weight: 400;
    }

    &__text {
        font-size: 8px;
        font-weight: 200;
        white-space: nowrap;
    }
}

.btn-workout {
    cursor: pointer;
    border-radius: 10px;
    background: #ea7777;
    font-size: 10px;
    font-weight: 600;
    color: #eaeaea;
    padding: 5px 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

// ========<MOBILE>

.mobile-info {
    position: relative;
    z-index: 3;
    &__row {
        // display: flex;
        // align-items: end;
        // justify-content: space-around;
        margin-bottom: 20px;
        width: 100%;
    }
    &__container {
        // margin-right: 10px;
        margin-bottom: 20px;
    }
    &__label {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 18px;
        @media (max-width: 400px) {
            font-size: 15px;
        }
        // padding: 7px;
    }
    &__select {
        padding: 7px;
        font-size: 18px;
        border-radius: 10px;
        width: 100%;
        @media (max-width: 400px) {
            font-size: 16px;
        }
    }
    &__tab {
    }

    &__content {
    }
}
