.containerBall {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // height: 142.999%;
}

#ball,
#ball2 {
    position: absolute;
    opacity: 0.85;
    width: 40px;
    height: 50px;
    /* transform: rotate(135deg); */
    background-image: url("../../../../public/images/HomePage/shuttlecock.svg");
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(135deg);
}
