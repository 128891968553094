.marquee {
    position: relative;
    z-index: 1;
    width: 100vw;
    max-width: 100%;
    height: 100px;
    overflow-x: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
}

.track {
    position: absolute;
    white-space: nowrap;
}

.track > div span {
    
    margin: 20px 0;
    font-size: 36px;
    font-weight: bold;
    text-transform: uppercase;
}

.text{
    margin-right: 50px !important;
}
.text-in{
    margin-right: 50px !important;
    &:last-child{
        margin: 0 !important;
    }
}
