.personal {
    padding-top: 110px;
    padding-bottom: 100px;
    position: relative;
    &::before {
        content: " ";
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.07;
        background-image: url("../../../public/images/HomePage/patern.png");
        background-repeat: repeat;
        background-position: 50% 0;
        // background-size: ;
    }
    &__title {
        color: #5b5b5b;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 50px;
        @media (max-width: 500px) {
            font-size: 24px;  
            margin-bottom: 30px;  
        }
    }
    &__row {
        position: relative;
        z-index: 4;
        // display: grid;
        // grid-template-columns: 0.2fr 0.8fr;
        // grid-column-gap: 70px;
        // display: flex;
        // align-items: center;
        display: flex;
        justify-content: space-between;
    }
    &__nav {
        position: relative;
        z-index: 4;
        flex: 1 0 20%;
        margin-right: 70px;
    }
    &__profile {
        position: relative;
        z-index: 4;
        flex: 1 1 80%;
    }
}

.nav-personal {
    // display: none;
    // display: inline-block;
    padding: 30px;
    border-radius: 10px;
    background: #ff6969;
    max-height: 170px;
    list-style: none;
    display: flex;
    flex-direction: column;
    @media (max-width: 1160px) {
        display: none;
    }

    &__link {
        cursor: pointer;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        transition: all 0.2s ease-in;
        &:hover {
            font-size: 20px;
            font-weight: 400;
        }
        margin-bottom: 20px;
        &:last-child {
            margin: 0;
        }
    }
}

.profile-personal {
    &__info {
        margin-bottom: 50px;
    }

    &__subscription {
        margin-bottom: 70px;
    }

    &__myworkouts {
        margin-bottom: 70px;
    }

    &__competitions {
    }
}

.info-profile {
    display: flex;
    align-items: center;
    // max-height: 30px;    
    justify-content: space-between;
    @media (max-width: 760px) {
        flex-direction: column;
        align-items: inherit;
    }
    &__person {
        display: flex;
        align-items: center;
        margin-right: 15px;
        @media (max-width: 760px) {
            margin: 0 0 15px 0;
        }
    }

    &__edit {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        border-radius: 10px;
        background: #ff6969;
        padding: 10px 15px;
        border: none;
        @media (max-width: 760px) {
            padding: 5px 10px;
            font-size: 14px;
        }
    }

    &__img {
        width: 124px;
        height: 124px;
        border-radius: 124px;
        overflow: hidden;
        margin-right: 25px;
        @media (max-width: 760px) {
            width: 70px;
            height: 70px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info-person {
    }
}

.info-person {
    &__name {
        color: #5a5a5a;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 8px;
        @media (max-width: 760px) {
            font-size: 20px;
        }
    }

    &__email {
        color: #5a5a5a;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px;
        @media (max-width: 760px) {
            font-size: 12px;
        }
    }

    &__username {
        color: #5a5a5a;
        font-size: 16px;
        font-weight: 700;
        @media (max-width: 760px) {
            font-size: 12px;
        }
    }
}

.subscription-profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    @media (max-width: 810px) {
        grid-column-gap: 20px;
    }
    @media (max-width: 760px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__card {
        @media (max-width: 760px) {
            margin-bottom: 20px;
        }
    }

    &__progressbars {
        @media (max-width: 760px) {
            max-width: 454px;
            width: 100%;
        }
    }

    &__progressbar {
    }
}

.card-subscription {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &__img {
        position: relative;
        // width: 100%;
        // height: 100%;
        @media (max-width: 760px) {
            text-align: center;
            max-width: 296.8px;
            max-height: 191.8px;
        }

        img {
            object-fit: contain;
            @media (max-width: 760px) {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__count {
        position: absolute;
        top: 85px;
        left: 55px;
        @media (max-width: 760px) {
            top: 59.5px;
            left: 38.5px;
        }
    }

    &__date {
        position: absolute;
        bottom: 35px;
        left: 40px;
        color: #5b5b5b;
        font-size: 20px;
        font-weight: 700;
        @media (max-width: 760px) {
            font-size: 14px;
            bottom: 24.5px;
            left: 28px;
        }
    }

    &__name {
        position: absolute;
        bottom: 35px;
        left: 245px;
        color: #5b5b5b;
        font-size: 24px;
        font-weight: 700;
        @media (max-width: 760px) {
            font-size: 16.8px;
            bottom: 24.5px;
            left: 171.5px;
        }
    }
}

.count-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    &__count {
        color: #5b5b5b;
        font-size: 48px;
        font-weight: 700;
        @media (max-width: 760px) {
            font-size: 33.6px;
        }
    }

    &__label {
        color: #5b5b5b;
        font-size: 20px;
        font-weight: 400;
        @media (max-width: 760px) {
            font-size: 14px;
        }
    }
}

.progressbar-profile {
    width: 100%;
    margin-bottom: 50px;
    &:last-child {
        margin: 0;
    }
    &__label {
        margin-bottom: 35px;
        color: #000;
        font-size: 20px;
        font-weight: 700;
        @media (max-width: 420px) {
            font-size: 14px;
        }
    }

    &__bg {
        position: relative;

        height: 12px;
        border-radius: 100px;
        background: #5b5b5b;
        padding: 2px;
        @media (max-width: 420px) {
            height: 8px;
        }
    }

    &__start {
        position: absolute;
        top: -23px;
        left: 0;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        @media (max-width: 420px) {
            font-size: 10px;
        }
    }

    &__end {
        top: -23px;
        right: 0;
        position: absolute;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        @media (max-width: 420px) {
            font-size: 10px;
        }
    }

    &__line {
        position: relative;
        width: 20%;
        height: 8px;
        border-radius: 100px;
        background: #f9fe08;
        @media (max-width: 420px) {
            height: 4px;
        }
    }

    &__current {
        position: absolute;
        bottom: -23px;
        right: 0;
        color: #5b5b5b;
        font-size: 15px;
        font-weight: 700;
        @media (max-width: 420px) {
            font-size: 9px;
        }
    }
}
.progressbar-competition{
    right: -22px;
}

.myworkouts-profile {
    &__title {
        color: #5b5b5b;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 35px;
        @media (max-width: 500px) {
            font-size: 24px;
        }
        @media (max-width: 420px) {
            font-size: 20px;
        }
    }

    &__workout {
    }
}

.workout-profile {
    width: 100%;
    position: relative;
    z-index: 3;
    padding: 10px 10px 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background: #5b5b5b;
    margin-bottom: 25px;
    &:last-child {
        margin: 0;
    }
    @media (max-width: 700px) {
        padding: 7px 7px 7px 12px;
        
    }
    &__title {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        margin-right: 20px;
        flex: 0 1 40%;
        @media (max-width: 1300px) {
            font-size: 18px;
        }
        @media (max-width: 1165px) {
            font-size: 16px;
        }
        
        @media (max-width: 700px) {
            font-size: 11px;
        }
        @media (max-width: 440px) {
            font-size: 8px;
        }
        @media (max-width: 335px) {
            font-size: 6px;
        }
    }

    &__date {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        margin-right: 20px;
        flex-shrink: 0;
        @media (max-width: 1165px) {
            font-size: 13px;
        }
        @media (max-width: 700px) {
            font-size: 8px;
        }
        @media (max-width: 500px) {
            font-size: 8px;
        }
        @media (max-width: 335px) {
            font-size: 6px;
        }
    }

    &__time {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        margin-right: 20px;
        flex-shrink: 0;
        @media (max-width: 1165px) {
            font-size: 13px;
        }
        @media (max-width: 700px) {
            font-size: 8px;
        }
        @media (max-width: 500px) {
            font-size: 8px;
        }
        @media (max-width: 335px) {
            font-size: 6px;
        }
    }
    &__active {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        @media (max-width: 940px) {
            // display: flex;
            align-items: center;
            flex-direction: column;
        }
    }

    &__follow {
        padding: 15px 20px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        border-radius: 10px;
        background: #ff6969;
        border: 0;
        @media (max-width: 1165px) {
            font-size: 13px;
            padding: 10px 15px;
        }
        @media (max-width: 940px) {
            width: 100%;
            // margin-bottom: 10px;
            margin-right: 0;
        }
        @media (max-width: 700px) {
            padding: 5px 15px;
            margin-right: 5px;
            font-size: 10px;
            border-radius: 5px;
        }
        @media (max-width: 500px) {
            font-size: 6px;

            // margin: 0 0 5px 0;
        }
    }
    &__people {
        margin-right: 20px;
        @media (max-width: 940px) {
            width: 100%;
            margin-bottom: 10px;
            margin-right: 0;
        }
    }
}

.competitions-profile {
    &__title {
        color: #5b5b5b;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 35px;
        @media (max-width: 500px) {
            font-size: 24px;
        }
        @media (max-width: 420px) {
            font-size: 20px;
        }
    }
}

// ===============================================================

.invite-pair {
    &__title {
        color: #5b5b5b;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 35px;
        @media (max-width: 500px) {
            font-size: 24px;
        }
        @media (max-width: 420px) {
            font-size: 20px;
        }
    }

    &__row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 36px;
        grid-row-gap: 36px;
        margin-bottom: 30px;
        @media (max-width: 860px) {
            grid-column-gap: 10px;
            grid-row-gap: 10px;
        }
        @media (max-width: 670px) {
            grid-template-columns: 1fr 1fr;
        }
        // @media (max-width: 470px) {
        //     grid-template-columns: 1fr;
        // }
    }

    &__item {
    }
}

.item-invite {
    padding: 20px 20px 10px 20px;
    border-radius: 20px;
    background: #5b5b5b;
    @media (max-width: 470px) {
        padding: 15px 15px 10px 15px;
    }
    &__title {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
        @media (max-width: 520px) {
            font-size: 15px;
        }
        @media (max-width: 470px) {
            font-size: 10px;
            margin-bottom: 15px;
        }
    }

    &__date {
        
    }

    &__time {
    }

    &__label {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 17px;
        @media (max-width: 470px) {
            font-size: 8px;
            margin-bottom: 10px;
        }
    }

    &__name {
        color: #f2d9d9;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 17px;
        @media (max-width: 470px) {
            font-size: 8px;
            margin-bottom: 10px;
        }
    }

    &__btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
    }
}

.date-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 470px) {
        font-size: 8px;
        margin-bottom: 10px;
    }
    &__label {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        @media (max-width: 470px) {
            font-size: 8px;
        }
    }

    &__date {
        color: #f2d9d9;
        font-size: 15px;
        font-weight: 400;
        @media (max-width: 470px) {
            font-size: 8px;
        }
    }
}
.time-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; 
    @media (max-width: 470px) {
        font-size: 8px;
        margin-bottom: 10px;
    }
    &__label {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        @media (max-width: 470px) {
            font-size: 8px;
        }
    }

    &__time {
        color: #f2d9d9;
        font-size: 15px;
        font-weight: 400;@media (max-width: 470px) {
            font-size: 8px;
        }
    }
}

.btns-item {
    margin: 0 auto;
    &__btn {
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        border-radius: 10px;
        background: #ff6969;
        padding: 16px 0;
        @media (max-width: 1160px) {
            font-size: 12px;
            padding: 10px 5px;
        }
        @media (max-width: 470px) {
            font-size: 6px;
            padding: 5px 5px;
        }
    }
}
.btns-join {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    &__btn {
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        border-radius: 10px;
        background: #ff6969;
        padding: 16px 20px;
        @media (max-width: 1160px) {
            max-width: 100px;
            width: 100%;
            font-size: 12px;
            padding: 10px 5px;
        }
        @media (max-width: 470px) {
            font-size: 6px;
            padding: 5px 5px;
        }
    }
}

.edit-modal{
    width: 100%;
}
.edit-modal-header{
    margin-bottom: 20px;
}

.edit-modal-form{
    margin-bottom: 25px;
    padding: 0 20px;
}