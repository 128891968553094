* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.container {
    max-width: 1280px;
    margin: 0 auto;
    @media (max-width: 1320px) {
        padding: 0 15px;
    }
}



@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@400;500;600;700&display=swap");



body {
    /* padding: 40px 0px 0 0px; */
    margin: 0;
    overflow-x: hidden;
    /* height: 100vh; */
}

body.stop{
    overflow: hidden;
}

body::-webkit-scrollbar {
    width: 10px;
    background-color: #f9f9fd;
    /* background-color: transparent; */

}
body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ED696B;
}
body::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); */
    border-radius: 10px;
    background-color: #f9f9fd;
}
.App{
    min-height: 100vh;
    overflow: hidden;
}





