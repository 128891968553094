.events {
    padding-top: 110px;
    padding-bottom: 100px;
    position: relative;
    min-height: 85vh;
    // &.stop{
    //     // & .container{
    //         overflow: hidden;
    //     // }
    // }
    &::before {
        content: " ";
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.07;
        background-image: url("../../../public/images/HomePage/patern.png");
        background-repeat: repeat;
        background-position: 50% 0;
        // background-size: ;
    }
    &__title {
        color: #5b5b5b;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 120px;
        @media (max-width: 500px) {
            font-size: 25px;
            margin-bottom: 110px;
        }
    }
    &__row {
        margin-bottom: 75px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 140px;
        grid-column-gap: 60px;
        @media (max-width: 970px) {
            grid-row-gap: 110px;
            grid-column-gap: 30px;
        }
        @media (max-width: 840px) {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }

    &__button {
        position: relative;
        z-index: 2;
        border-radius: 5px;
        border: 1px solid #000;
        background: #fff;
        color: #5b5b5b;
        font-size: 24px;
        font-weight: 700;
        padding: 19px 75px;
        transition: all 0.3s ease-in;
        display: inline-block;

        &:hover {
            box-shadow: 3px 3px 0px 0px #000;
        }
        @media (max-width: 840px) {
            padding: 10px 30px;
            font-size: 18px;
        }
    }

    &__event {
    }
}

.event {
    position: relative;
    width: 100%;
    max-width: 700px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    &__info {
    }

    &__content {
    }
}

.info-event {
    position: absolute;
    top: -80px;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 25px 40px 25px;
    width: 100%;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);

    border-radius: 15px;
    background: #ffe5e5;
    &__left {
    }
    &__right {
        text-align: right;
    }
    @media (max-width: 440px) {
        // padding: 5px 10px;
        font-size: 10px;
        padding: 15px 15px 40px 15px;
        top: -60px;
    }
}
.left-info {
    &__label {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        @media (max-width: 1145px) {
            font-size: 18px;
        }
        @media (max-width: 440px) {
            font-size: 16px;
        }
    }

    &__start {
        color: rgba(91, 91, 91, 0.8);
        font-size: 12px;
        font-weight: 700;
        @media (max-width: 1145px) {
            font-size: 10px;
        }
    }
}

.right-info {
    &__date {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        @media (max-width: 1145px) {
            font-size: 18px;
        }
        @media (max-width: 440px) {
            font-size: 16px;
        }
    }

    &__label {
        color: rgba(91, 91, 91, 0.8);
        font-size: 12px;
        font-weight: 700;
        @media (max-width: 1145px) {
            font-size: 10px;
        }
    }
}

.content-event {
    position: relative;
    z-index: 3;
    padding: 25px 25px 15px 25px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15),
        0px -4px 4px 0px rgba(0, 0, 0, 0.15);
    @media (max-width: 440px) {
        padding: 15px 15px 10px 15px;
        max-height: 350px;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &__img {
        width: 100%;
        // height: 100%;
        height: 300px;
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 15px;
        @media (max-width: 440px) {
            height: 230px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__title {
        display: block;
        display: -webkit-box;
        color: #000;
        font-size: 20px;
        font-weight: 400;
        margin-right: 35px;
        max-width: 430px;
        height: 50px;
        -webkit-line-clamp: 2; /* количество строк */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // height: 50px;
        // text-overflow: ellipsis;
        @media (max-width: 1145px) {
            font-size: 16px;
            height: 40px;
        }
        @media (max-width: 440px) {
            font-size: 14px;
            -webkit-line-clamp: 2; /* количество строк */
            height: 35px;
        }
    }

    &__button {
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #000;
        background: #fff;
        color: #5b5b5b;
        font-size: 14px;
        font-weight: 700;
        transition: all 0.3s ease-in;
        padding: 10px 20px;
        &:hover {
            box-shadow: 3px 3px 0px 0px #000;
        }
        @media (max-width: 440px) {
            padding: 5px 10px;
            font-size: 10px;
        }
    }

    &__time {
    }
}

.time-event {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__time {
        color: rgba(91, 91, 91, 0.8);
        font-size: 12px;
        font-weight: 700;
    }

    &__date {
        color: rgba(91, 91, 91, 0.8);
        font-size: 12px;
        font-weight: 700;
    }
}

.container-btn {
    width: 100%;
    text-align: center;
}

// ================================================

.modal-window-event {
    display: none;
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translate(-50%);
    z-index: 50;
    max-width: 830px;
    width: 94%;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);

    &__bg {
        display: none;
        position: fixed;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(217, 217, 217, 0.95);
        z-index: 49;
        &.active {
            display: block;
        }
        // padding: 10px;
    }
    &__info {
    }

    &__content {
    }
    &.active {
        display: block;
    }
}
.info-modal {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    padding: 20px 30px 40px 30px;
    border-radius: 15px;
    // background: #ffe5e5;
    position: absolute;
    height: 200px;
    top: -90px;
    left: 0;
    z-index: 1;
    width: 100%;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    &__title {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        margin-right: 20px;
        @media (max-width: 805px) {
            font-size: 16px;
        }
        @media (max-width: 425px) {
            font-size: 14px;
        }
    }
    @media (max-width: 425px) {
        padding: 15px 15px 40px 15px;
    }

    &__date {
    }
}

.date-modal {
    text-align: right;

    &__date {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        @media (max-width: 805px) {
            font-size: 16px;
        }
    }

    &__label {
        color: rgba(91, 91, 91, 0.8);
        font-size: 12px;
        font-weight: 700;
        @media (max-width: 805px) {
            font-size: 8px;
        }
    }
}

.content-modal {
    position: relative;
    z-index: 3;
    padding: 20px 30px 15px 30px;
    border-radius: 10px;
    // display: flex;
    // flex-direction: column;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px -4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 77vh;
    overflow: hidden;
    overflow-y: scroll;
    &__text {
        color: #5b5b5b;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 20px;
        // flex: 1 1 100%;
        @media (max-width: 805px) {
            font-size: 16px;
        }
        @media (max-width: 510px) {
            font-size: 14px;
        }
    }
    @media (max-width: 510px) {
        font-size: 14px;
        padding: 10px 15px 15px 15px;
    }

    &__img {
        border-radius: 10px;
        overflow: hidden;

        width: 100%;
        // flex-shrink: 0;
        // height: 100%;
        margin-bottom: 15px;
        & img {
            width: 100%;
            // height: 100%;
            object-fit: contain;
        }
    }

    &__time {
    }
}

.time-modal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__time {
        color: rgba(91, 91, 91, 0.8);
        font-size: 12px;
        font-weight: 700;
    }

    &__date {
        color: rgba(91, 91, 91, 0.8);
        font-size: 12px;
        font-weight: 700;
    }
}
