.competitions {
    padding-top: 110px;
    padding-bottom: 100px;
    position: relative;
    min-height: 85vh;
    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.07;
        background-image: url("../../../public/images/HomePage/patern.png");
        background-repeat: repeat;
        background-position: 50% 0;
    }
    &__title {
        color: #5b5b5b;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 70px;
        @media (max-width: 500px) {
            font-size: 25px;
            margin-bottom: 40px;
        }
    }

    &__row {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__event {
    }

    &__bg{
        display: none;
        position: fixed;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(217, 217, 217, 0.95);
        z-index: 49;
        padding: 10px;
        &.active {
            display: block;
        }
    }

    &__modal{}
}

.modal-competition-info{
    color: #fff;
    position: relative;
    z-index: 4;
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
    z-index: 50;
    max-width: 800px;
    width: 100%;
    background: #ea7777;
    padding: 30px 30px;
    border-radius: 20px;
    display: flex;
    // align-items: center;
    flex-direction: column;
}

.event-competitions {
    width: 100%;
    position: relative;
    z-index: 3;
    padding: 10px 10px 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background: #5b5b5b;
    margin-bottom: 25px;
    @media (max-width: 500px) {
        margin-bottom: 15px;
    }
    &:last-child {
        margin: 0;
    }
    @media (max-width: 1000px) {
        padding: 5px 5px 5px 15px;
    }
    &__btns {
        @media (max-width: 500px) {
            display: flex;
            // align-items:;
            flex-direction: column;
        }
    }
    &__title {
        cursor: pointer;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        margin-right: 20px;
        text-decoration: underline;
        @media (max-width: 1000px) {
            font-size: 16px;
        }
        @media (max-width: 700px) {
            font-size: 12px;
        }
        @media (max-width: 440px) {
            font-size: 8px;
        }
    }

    &__date {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        margin-right: 20px;
        @media (max-width: 1000px) {
            font-size: 12px;
        }
        @media (max-width: 700px) {
            font-size: 8px;
        }
        @media (max-width: 500px) {
            font-size: 8px;
        }
    }

    &__time {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        margin-right: 20px;
        @media (max-width: 1000px) {
            font-size: 12px;
        }
        @media (max-width: 700px) {
            font-size: 8px;
        }
        @media (max-width: 500px) {
            font-size: 8px;
        }
    }

    &__list {
        cursor: pointer;
        display: block;
        text-align: center;
        padding: 15px 20px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        border-radius: 10px;
        background: #ff6969;
        margin-right: 20px;
        border: 0;
        @media (max-width: 1000px) {
            padding: 10px 15px;
            margin-right: 10px;
            font-size: 14px;
        }
        @media (max-width: 700px) {
            padding: 5px 15px;
            margin-right: 5px;
            font-size: 10px;
            border-radius: 5px;
        }
        @media (max-width: 500px) {
            font-size: 6px;

            margin: 0 0 5px 0;
        }
    }

    &__follow {
        cursor: pointer;
        padding: 15px 20px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        border-radius: 10px;
        background: #ff6969;
        border: 0;
        @media (max-width: 1000px) {
            padding: 10px 15px;
            font-size: 14px;
        }
        @media (max-width: 700px) {
            padding: 5px 15px;
            margin-right: 5px;
            font-size: 10px;
            border-radius: 5px;
        }
        @media (max-width: 500px) {
            font-size: 6px;

            margin: 0 0 0 0;
        }
    }
    &__btns{
        display: flex;
    }
}
