.applications {
    padding-top: 110px;
    padding-bottom: 100px;
    position: relative;
    min-height: 85vh;

    &::before {
        content: " ";
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.07;
        background-image: url("../../../public/images/HomePage/patern.png");
        background-repeat: repeat;
        background-position: 50% 0;
        // background-size: ;
    }
    &__add-pair{
        position: relative;
        z-index: 3;
        cursor: pointer;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 10px;
        background: #ff6969;
        padding: 10px 20px;
        border: 0;
        margin-bottom: 20px;

    }
    &__title {
        color: #5b5b5b;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 50px;
        @media (max-width: 670px) {
            font-size: 20px;
            margin-bottom: 40px;
        }
        @media (max-width: 500px) {
            font-size: 18px;
            margin-bottom: 40px;
        }
    }

    &__modal {
    }

    &__row {
        position: relative;
        z-index: 3;
        margin-left: 25px;
    }

    &__pair {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        &:last-child {
            margin: 0;
        }
        @media (max-width: 670px) {
            font-size: 16px;
            margin-bottom: 10px;
        }
        @media (max-width: 500px) {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }

    &__button {
        cursor: pointer;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        padding: 5px 10px;
        border-radius: 10px;
        background: #ff6969;
        border: 0;
        @media (max-width: 670px) {
            font-size: 12px;
            padding: 3px 8px;
            border-radius: 5px;
        }
        @media (max-width: 500px) {
            font-size: 8px;
            padding: 3px 5px;
            border-radius: 5px;
        }
    }
}

// ======================================================================

.modal-applications {
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
    z-index: 50;
    max-width: 800px;
    width: 100%;
    background: #ea7777;
    padding: 15px;
    border-radius: 20px;
    
    
    @media (max-width: 760px) {
        max-height: 590px;
        overflow-y: scroll;
    }

    &__bg {
        display: none;
        position: fixed;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(217, 217, 217, 0.95);
        z-index: 49;
        &.active {
            display: block;
        }
    }

    &__row {
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        color: #fff;
        font-size: 24px;
        font-weight: 800;
        @media (max-width: 760px) {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }

    }

    &__close {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        width: 49px;
        height: 49px;
        border-radius: 10px;
        background: #db5555;
    }

    &__mine {
    }

    &__find {
    }
}

.mine-applications {
    display: flex;
    align-items: center;
    flex-direction: column;
    &__fitst-time {
    }

    &__title {
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 10px;
        @media (max-width: 760px) {
            font-size: 16px;
            font-weight: 600;
        }
    }

    &__label {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        margin-right: 20px;
    }

    &__input-rating {
        width: 100px;
        color: #fff;
        font-size: 18px;
        font-weight: 200;
        border-radius: 10px;
        background: #db5555;
        padding: 10px;
        border: 0;
        outline: none;
        @media (max-width: 760px) {
            font-size: 15px;
            padding: 7px;
        }

    }

    &__instruction {
        color: #fff;
        margin-bottom: 10px;
        padding-left: 20px;
    }

    &__item {
    }

    &__form {
        margin-bottom: 15px;
    }

    &__button{
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 10px;
        background: #5a5a5a;
        padding: 10px 20px;
        border: 0;
    }
}

.item-instruction {
    &__text {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 10px;
        @media (max-width: 760px) {
            font-size: 14px;
        }
    }

    &__img {
        // max-height: 100px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        img {
            height: 100%;
            width: 60%;
            object-fit: fill;
            // @media (max-width: 760px) {
            //     width: 40%;
            // }
        }
    }
}

.find-person {
    display: flex;
    align-items: center;
    flex-direction: column;
    // flex-basis: 100%;

    &__text {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 20px;
        @media (max-width: 760px) {
            font-size: 14px;
        }
    }

    &__form {
        width: 100%;

    }

    &__input {
        color: #fff;
        font-size: 18px;
        font-weight: 200;
        border-radius: 10px;
        background: #db5555;
        padding: 10px;
        border: 0;
        width: 100%;
        outline: none;
        margin-bottom: 10px;
        @media (max-width: 760px) {
            font-size: 15px;
            padding: 7px;
        }
    }

    &__button {
        cursor: pointer;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 10px;
        background: #5a5a5a;
        padding: 10px 20px;
        border: 0;
        @media (max-width: 760px) {
            font-size: 14px;
            font-weight: 400;
            padding: 5px 10px;
        }
    }
}

.find-users{
    list-style: none;
    font-size: 18px;
    color: #fff;
    // background: #c3c3c3;
    border: 1px solid #fff;
    padding: 3px;
    border-radius: 10px;
    margin-bottom: 20px;
    max-height: 191px;
    overflow: hidden;
    &__user{
        padding: 10px;
        background: #db5555;
        margin-bottom: 5px;
        border-radius: 10px;
        &:last-child{
            margin: 0;
        }
        @media (max-width: 760px) {
            padding: 5px 10px;
        }
    }
    @media (max-width: 760px) {
        font-size: 15px;
    }
    
}