.main-container{
    position: relative;
    height: 170px;
    width: 1100px;
    display: flex;
    align-items: center;
}

.window{
    height: 100%;
    width: 100%;
    overflow: hidden;
    /* margin: 0 10px 0 10px; */
}
.all-pages-container{
    height: 100%;
    display: flex;
    align-items: center;

    transition: translate;
    transition-property: transform;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
    
}
.arrow-left{
    position: absolute;
    top: 50%;
    left: -39px;
    
    transform: translate(0, -50%) rotate(-180deg);

}
.arrow-right{
    position: absolute;
    top: 50%;
    right: -39px;
    transform: translate(0, -50%);
}

.shadow{
    position: absolute;
    top: 0;
    right: -2px;
    height: 100%;
    width: 100px;
    background-image: linear-gradient(to right, transparent, #EAEAEA);
}