.following-btn {
    position: fixed !important;
    bottom: 160px;
    right: -40px;
    display: flex;
    // align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 3;

    &__btn {
        cursor: pointer;
        position: relative;
        z-index: 4;
        width: 166px;
        height: 43px;
        border-radius: 10px;
        background: #EA7173;
        box-shadow: 5px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 10px 25px;
        color: #FFF;
        text-align: center;
        font-size: 18px;
        transform: rotate(90deg);
    }

    &__img {
        position: absolute;
        z-index: 2;
        top: 100px;
        left: 58px;
    }

}