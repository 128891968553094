.card {
    @media (max-width: 1050px) {
        &.right {
            height: 590px;
        }
    }
    @media (max-width: 525px) {
        &.right {
            height: 500px;
        }
    }
    @media (max-width: 440px) {
        &.right {
            height: 450px;
        }
    }
    
    &.left {
        height: 300px;
        @media (max-width: 525px) {
            height: 230px;
        }
    }
    &.active {
        .card__front {
            transform: rotateX(-180deg);
        }

        .card__back {
            transform: rotateX(0deg);
        }
    }

    &__front.left {
        display: flex;
        // flex-direction: column;
        align-items: center;
        position: relative;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: #000;
        backface-visibility: hidden;
        transform: rotateX(0deg);
        transition: 0.5s;
    }

    &__front.right {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: #000;
        backface-visibility: hidden;
        transform: rotateX(0deg);
        transition: 0.5s;
    }

    &__back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #5b5b5b;
        backface-visibility: hidden;
        transform: rotateX(180deg);
        transition: 0.5s;
    }

    &__img.right {
        height: 100%;
        width: 100%;
        max-height: 540px;
        border-radius: 30px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__img.left {
        flex: 0 0 270px;
        height: 100%;
        overflow: hidden;
        border-radius: 30px;
        @media (max-width: 525px) {
            flex: 0 0 170px;
        }
        @media (max-width: 405px) {
            flex: 0 0 140px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.content-card-left {
    text-align: center;
    padding: 10px;

    &__title {
        color: #ed696b;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
        // hyphens: auto;
        @media (max-width: 1200px) {
            font-size: 18px;
        }
        @media (max-width: 440px) {
            margin-bottom: 10px;
            font-size: 15px;
        }
        @media (max-width: 372px) {
            hyphens: auto;
        }
        @media (max-width: 352px) {
            font-size: 13px;
        }

    }

    &__text {
        color: #000;
        text-align: center;
        font-size: 16px;
        margin-bottom: 35px;
        @media (max-width: 1200px) {
            font-size: 14px;
        }
        @media (max-width: 525px) {
            margin-bottom: 15px;
            font-size: 12px;
        }
        @media (max-width: 352px) {
            font-size: 10px;
        }

    }

    &__btn {
        padding: 15px 70px 15px 15px;
        background: url("../../../../public/images/HomePage/why-club/more-info.svg")
            120px center no-repeat;
        @media (max-width: 425px) {
            padding: 10px 40px 10px 10px;
            background: url("../../../../public/images/HomePage/why-club/more-info.svg")
            100px center no-repeat;
        }
        @media (max-width: 352px) {
            padding: 10px 40px 10px 10px;
            background: url("../../../../public/images/HomePage/why-club/more-info.svg")
            83px center no-repeat;
            font-size: 10px;
        }
    }
}



.content-card-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin: auto 0;
    width: 100%;

    &__title {
        color: #ea7173;
        font-size: 20px;
        font-weight: 700;
        margin-right: 30px;
        hyphens: auto;
        @media (max-width: 1200px) {
            font-size: 18px;
        }
        @media (max-width: 425px) {
            font-size: 15px;
        }
        @media (max-width: 372px) {
            margin-right: 10px;
            hyphens: auto;
        }
        @media (max-width: 372px) {
            font-size: 13px;
        }
    }

    &__btn {
        padding: 15px 70px 15px 15px;
        background: url("../../../../public/images/HomePage/why-club/more-info.svg")
            120px center no-repeat;
        @media (max-width: 425px) {
            padding: 10px 40px 10px 10px;
            background: url("../../../../public/images/HomePage/why-club/more-info.svg")
            100px center no-repeat;
        }
        @media (max-width: 352px) {
            padding: 10px 40px 10px 10px;
            background: url("../../../../public/images/HomePage/why-club/more-info.svg")
            83px center no-repeat;
            font-size: 10px;
        }
    }
}

.back-card {
    text-align: center;
    color: #fff;
    padding: 20px 45px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 390px) {
        // align-items: start;
        
        padding: 10px 10px;
    }
    &__title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 15px;
        @media (max-width: 1200px) {
            font-size: 18px;
        }
        @media (max-width: 525px) {
            font-size: 15px;
        }
        @media (max-width: 390px) {
            margin-bottom: 10px;
        }
        
    }

    &__text {
        font-size: 18px;
        font-weight: 200;
        line-height: 109.7%;
        @media (max-width: 1200px) {
            font-size: 16px;
        }
        @media (max-width: 525px) {
            font-size: 13px;
        }
    }
}
